import { generateArray } from "Common/Utility/generator";
import classNames from "classnames";
import { Skeleton } from 'primereact/skeleton';


export default function CardListLoader() {
    return generateArray(8).map((_, index) =>
        <div key={index} className={classNames("max-w-sm rounded overflow-hidden shadow-lg")}>
            <div className="w-full h-48 object-cover bg-gray-400" ></div>

            <div className="px-6 pt-4 pb-2 relative flex flex-row justify-between">
                <Skeleton className="mb-2"></Skeleton>
            </div>
            <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">
                    <Skeleton className="mb-2"></Skeleton>
                </div>
                <div>
                </div>
            </div>
        </div>
    )
}