

export const SearchParams = {
    isParameterValueMatch: (value, compare) => value === compare ? true : (value || '').split(',').map(valueVariant => valueVariant === compare).filter(check => check === true).length > 0,
    isExactMatch: (searchParams, matches) => Object.keys(matches).map(label => SearchParams.isParameterValueMatch(searchParams.get(label), matches[label])).filter(check => check === true).length === Object.values(matches).length,
    isPartialMatch: (searchParams, matches) => Object.keys(matches).map(label => searchParams.get(label) === matches[label]).filter(check => check === true).length > 0,
    toggle: (setSearchParams, searchParams, parameters, toggleValue, multiple = false) => setSearchParams(
        multiple ?
            toggleValue ? SearchParams.appends(searchParams, parameters) : SearchParams.removes(searchParams, parameters) :
            SearchParams.sanitize(SearchParams.updates(searchParams, parameters))
    ),
    removes: (searchParams, parameters) => {
        let update = SearchParams.current(searchParams)
        Object.keys(parameters).map(labelSeek => {
            if (update[labelSeek]) {
                update[labelSeek] = update[labelSeek].split(',').filter(check => check !== parameters[labelSeek]).join(',')
            }
            if (update[labelSeek] === '') {
                delete update[labelSeek]
            }
        })
        return update
    },
    appends: (searchParams, parameters) => {
        let update = SearchParams.current(searchParams)
        Object.keys(parameters).map(labelSeek => {
            if (update[labelSeek]) {
                update[labelSeek] = [...update[labelSeek].split(','), parameters[labelSeek]].join(',')
            } else {
                update[labelSeek] = parameters[labelSeek]
            }
        })
        return update
    },
    updates: (searchParams, parameters) => ({ ...SearchParams.filter(searchParams, parameters), ...(parameters) }),
    current: (searchParams) => Object.fromEntries(searchParams.entries()),

    filter: (searchParams, parameters) => Object.fromEntries(Array.from(searchParams.entries()).filter(([label, _]) => !parameters[label])),
    sanitize: (parameters) => Object.fromEntries(Object.entries(parameters).filter(([_, value]) => value != null))

}