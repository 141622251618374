import defaultObjectImmutable from 'object.defaults/immutable'

export function formatDate(input) {
    let date = input instanceof Date ? input : (new Date(input));
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let year = date.getFullYear();
    return [year, month, day].join('-');
}


export function transformResponseToInfiniteList(data, defaultValue = []) {
    return data?.pages.map(page => page.data.data.list).flat() || defaultValue
}

export function transformResponseToInfiniteHaveNext(data, defaultValue = []) {
    return data?.pages[data?.pages.length-1].data.data.pagination.isMore
}

export function transformResponseToList(data, defaultValue = []) {
    return data?.data?.data?.list || defaultValue
}

export function transformResponseToPagination(data, defaultValue = { currentPage: 1, pageSize: 10, pageCount: 0, isMore: true, itemCount: 0 }) {
    return data?.data?.data?.pagination || defaultValue
}

export function transformResponseToSingle(data, defaultValue = null) {
    return data?.data?.data || defaultValue
}

export function transformResponseError(error, defaultValue = {}) {
    return  error?.response.data.errors || defaultValue
}

export function transformErrorNetwork(error) {
    if (error.response) {
        switch (parseInt(error.response.status)) {
            case 500: return 'Sedang terjadi kesalahan pada Server'
            case 404: return 'Koneksi ke server tidak temukan'
            case 403: return 'Kamu tidak memiliki akses'
            case 422: {
                const errors = Object.values(error.response.data?.errors || {}).flat()
                return errors.length > 0 ? errors[0] : 'Ada salah input, periksa kembali'
            }
            default: return 'Unknown'
        }
    }
    if (error.request) {
        return error.request._response || 'Error on Request'
    }
    if (error.message) {
        return error.message
    }
    return typeof error === 'object' ? JSON.stringify(error) : error
}


export function objectFilterNull(object) {
    return Object.keys(object).reduce((accumulator, key) => {
        if (object[key] != null) {
            accumulator[key] = object[key];
        }
        return accumulator;
    }, {})
}

export function toInitial(string) {
    const parts = string.split(' ')
    return parts.shift().charAt(0) + (parts.length >= 1 ? parts.pop().charAt(0).toUpperCase() : '')
}

export function defaultObject(object, defaultValue) {
    return defaultObjectImmutable(object, defaultValue)
}