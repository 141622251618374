import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Icon from '../Component/Icon'
import { create } from 'zustand'
import { shallow } from 'zustand/shallow'
import React from 'react'
import Input from '../Component/Input'
import { cn } from 'Common/Utility/class-name'
import AlertView from './AlertView'
import Button from '../Component/Button'

const initialState = {
  isShow: false,
  icon: Icon.IconPhospor.CheckCircle,
  iconColor: 'green',
  password: '',
  usingPassword: false,
  errorPassword: null,
  isLoading: false
}

export const useConfirmDialogStore = create((set) => ({
  ...initialState,
  onConfirm: () => null,
  reset: () => set(() => (initialState)),
  setShow: (value) => set(() => ({ isShow: value })),
  setPassword: (value) => set(() => ({ password: value })),
  setErrorPassword: (value) => set(() => ({ errorPassword: value })),
  setIsLoading: (value) => set(() => ({ isLoading: value })),
  confirm: ({ icon, iconColor, title, message, onConfirm, usingPassword }) => {
    let update = { isShow: true, title, message, onConfirm, usingPassword }
    if (icon) {
      update['icon'] = icon
    }
    if (iconColor) {
      update['iconColor'] = iconColor
    }
    return set(() => update)
  },
}))

export function ConfirmDialog() {
  const isShow = useConfirmDialogStore(state => state.isShow)
  const { title, message, icon, iconColor, usingPassword, password, errorPassword, isLoading } = useConfirmDialogStore(state => ({
    title: state.title,
    message: state.message,
    icon: state.icon, iconColor: state.iconColor,
    usingPassword: state.usingPassword, password: state.password, errorPassword: state.errorPassword,
    isLoading: state.isLoading
  }), shallow)
  const reset = useConfirmDialogStore(state => state.reset)
  const setShow = useConfirmDialogStore(state => state.setShow)
  const onConfirm = useConfirmDialogStore(state => state.onConfirm)
  const setPassword = useConfirmDialogStore(state => state.setPassword)
  const setIsLoading = useConfirmDialogStore(state => state.setIsLoading)

  const cancelButtonRef = useRef(null)

  const onClose = () => {
    setShow(false)
    setIsLoading(false)
    reset()
  }


  return (
    <Transition.Root show={isShow} as={Fragment}>
      <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-${iconColor}-100`}>
                  {React.createElement(icon, { className: `h-6 w-6 text-${iconColor}-600`, 'aria-hidden': 'true' })}
                </div>


                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {message}
                    </p>
                  </div>
                </div>
              </div>
              {!usingPassword ? null :
                <div className='mt-7 grid grid-cols-1 gap-8 md:gap-3'>
                  <div>
                    <span className="p-float-label">
                      <Input.InputSecure
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={cn('w-full', errorPassword ? 'p-invalid' : null)}
                      />
                      <label htmlFor="morningActivity">Kata Sandi</label>
                    </span>

                    <AlertView.ErrorInput errors={errorPassword} />
                  </div>
                </div>
              }

              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <Button.LoadableButton variant='secondary' color='gray' onClick={onClose} ref={cancelButtonRef}>
                  Cancel
                </Button.LoadableButton>
                <Button.LoadableButton variant='primary' color='sky'
                  loading={isLoading || false} loadingText='Processing...'
                  onClick={() => {
                    const response = onConfirm(password)
                    if (response instanceof Promise) {
                      setIsLoading(true)
                      response
                        .then(() => onClose())
                        .catch(() => {
                          setIsLoading(false)
                        })
                    } else {
                      setIsLoading(false)
                      onClose()
                    }
                  }}
                >
                  Confirm
                </Button.LoadableButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}