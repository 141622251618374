import { requestGet } from "Common/Provider/RestClient"
import { useQuery } from "@tanstack/react-query"

export function useDetailMyCredential({ onSuccess, onError, enabled = true }) {
    const path = `/authentication/credential/my-credential`
    const data = useQuery({
        queryKey: [path, ],
        queryFn: () => requestGet(path, { }),
        enabled: enabled,
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return data
}