
import { Badge } from "@tremor/react";


export function MediaSizeDetector() {
    if (process.env.NODE_ENV !== 'development') {
        return null
    }
    return (
        <div className="fixed opacity-40 hover:opacity-100 top-3 right-3">
            <div className="block sm:hidden">
                <Badge color="amber" size="sm">
                    X-Small (Mobile)
                </Badge>
            </div>
            <div className="hidden sm:block md:hidden">
                <Badge color="lime" size="sm" >
                    Small
                </Badge>
            </div>
            <div className="hidden md:block lg:hidden">
                <Badge color="green" size="sm" >
                    Medium
                </Badge>
            </div>
            <div className="hidden lg:block xl:hidden">
                <Badge color="cyan" size="sm" >
                    Large
                </Badge>
            </div>
            <div className="hidden xl:block 2xl:hidden">
                <Badge color="blue" size="sm" >
                    X-Large
                </Badge>
            </div>
            <div className="hidden 2xl:block">
                <Badge color="blue" size="sm" >
                    X-Large 2
                </Badge>
            </div>
        </div>
    )
}